/**
* Template Name: Shuffle - v4.1.0
* Template URL: https://bootstrapmade.com/bootstrap-3-one-page-template-free-shuffle/
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
body {
  font-family: "Open Sans", sans-serif;
  color: #334240;
}

.text-orange{
  /*color: #1bbca3;*/
  color: #FB937C;
}


a {
  text-decoration: none;
  color: #0F21C4;
}

a:hover {
  color: #0B1891;
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Roboto", sans-serif;
}

/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 99999;
  background: #1bbca3;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  transition: all 0.4s;
}

.back-to-top i {
  font-size: 24px;
  color: #fff;
  line-height: 0;
}

.back-to-top:hover {
  background: #21dfc2;
  color: #fff;
}

.back-to-top.active {
  visibility: visible;
  opacity: 1;
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
  width: 100%;
  min-height: 100vh;
  background-color: rgba(21, 27, 26, 0.7);
  /*overflow: hidden;*/
  padding: 0;
}

#hero .carousel-item {
  /*width: 100%;*/
  width: 100vw;
  min-height: 100vh;
  background-size: cover;
  background-position: center;
  /*background-repeat: no-repeat;*/
}

#hero .carousel-background{
  background-image: url("assets/img/slide/slide-0.jpg") fixed center center;
  background-size: cover;
}

#hero .carousel-item::before {
  content: '';
  background-color: rgba(21, 27, 26, 0.7);
  position: absolute;
  min-height: 100vh;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

#hero .carousel-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#hero .carousel-content {
  text-align: center;
}


#hero .carousel-inner .carousel-item {
  transition-property: opacity;
  background-position: center top;
}

#hero .carousel-inner .carousel-item,
#hero .carousel-inner .active.carousel-item-start,
#hero .carousel-inner .active.carousel-item-end {
  opacity: 0;
}

#hero .carousel-inner .active,
#hero .carousel-inner .carousel-item-next.carousel-item-start,
#hero .carousel-inner .carousel-item-prev.carousel-item-end {
  opacity: 1;
  transition: 0.5s;
}

#hero .carousel-inner .carousel-item-next,
#hero .carousel-inner .carousel-item-prev,
#hero .carousel-inner .active.carousel-item-start,
#hero .carousel-inner .active.carousel-item-end {
  left: 0;
  transform: translate3d(0, 0, 0);
}

#hero .carousel-control-prev, #hero .carousel-control-next {
  width: 10%;
}

#hero .carousel-control-next-icon, #hero .carousel-control-prev-icon {
  background: none;
  font-size: 48px;
  line-height: 1;
  width: auto;
  height: auto;
}

#hero .carousel-indicators li {
  cursor: pointer;
  opacity: 1;
}

#hero .carousel-indicators .active {
  background-color: #4154f1;
}

.btn-get-started {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 12px 32px;
  border-radius: 50px;
  transition: 0.5s;
  line-height: 1;
  margin: 10px;
  color: #fff;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
  border: 2px solid #d06a53;
}

.btn-get-started:hover {
  background: #d06a53;
  color: #fff;
  text-decoration: none;
}

.btn-more{
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 12px 32px;
  border-radius: 50px;
  transition: 0.5s;
  line-height: 1;
  margin: 10px;
  color: #d06a53;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
  border: 2px solid #d06a53;
}

.btn-more:hover {
  background: #d06a53;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}

.btn-start{
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 12px 32px;
  border-radius: 50px;
  transition: 0.5s;
  line-height: 1;
  margin: 10px;
  color: #fff;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
  border: 2px solid white;
}

.btn-start:hover {
  background: #fff;
  color: #d06a53;
  text-decoration: none;
  cursor: pointer;
}

@media (max-width: 768px) {
  #hero h2 {
    font-size: 28px;
  }
}

@media (min-width: 1024px) {
  #hero p {
    width: 60%;
  }
  #hero .carousel-control-prev, #hero .carousel-control-next {
    width: 5%;
  }
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  padding: 60px 0;
}

.section-gradient{
  background: linear-gradient(rgba(208, 106, 83, 0.7), rgba(187, 75, 50, 0.9));
  /*background: linear-gradient(rgba(232, 201, 92, 0.6), rgba(208, 106, 83, 0.6));*/
}

.section-bg {
  background-color: #f2f5f4;
}

.section-title {
  text-align: center;
  padding-bottom: 30px;
}

.section-title h2 {
  font-size: 32px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 0;
  /*color: #4b605c;*/
  color: #d06a53;
}

.section-title p {
  margin-bottom: 0;
  color: #5f7c78;
}

/*--------------------------------------------------------------
# Breadcrumbs
--------------------------------------------------------------*/
.breadcrumbs {
  padding: 15px 0;
  background-color: #f2f5f4;
  min-height: 40px;
}

.breadcrumbs h2 {
  font-size: 24px;
  font-weight: 300;
}

.breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 14px;
}

.breadcrumbs ol li + li {
  padding-left: 10px;
}

.breadcrumbs ol li + li::before {
  display: inline-block;
  padding-right: 10px;
  color: #6c757d;
  content: "/";
}

@media (max-width: 768px) {
  .breadcrumbs .d-flex {
    display: block !important;
  }
  .breadcrumbs ol {
    display: block;
  }
  .breadcrumbs ol li {
    display: inline-block;
  }
}

/*--------------------------------------------------------------
# About Us
--------------------------------------------------------------*/
.about .content h3 {
  font-weight: 500;
  font-size: 24px;
  color: #58716d;
  font-family: "Poppins", sans-serif;
}

.about .content ul {
  list-style: none;
  padding: 0;
}

.about .content ul li {
  padding-bottom: 10px;
}

.about .content ul i {
  font-size: 20px;
  padding-right: 4px;
  color: #1bbca3;
}

.about .content p:last-child {
  margin-bottom: 0;
}

.about .progress {
  height: 50px;
  display: block;
  background: none;
}

.about .progress .skill {
  padding: 10px 0;
  margin: 0 0 6px 0;
  text-transform: uppercase;
  display: block;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: #58716d;
}

.about .progress .skill .val {
  float: right;
  font-style: normal;
}

.about .progress-bar-wrap {
  background: #e6eceb;
}

.about .progress-bar {
  width: 1px;
  height: 10px;
  transition: .9s;
  background-color: #1bbca3;
}

/*--------------------------------------------------------------
# Counts
--------------------------------------------------------------*/
.counts .count-box {
  box-shadow: -10px -5px 40px 0 rgba(0, 0, 0, 0.1);
  padding: 30px;
  width: 100%;
  background: #fff;
}

.counts .count-box i {
  display: block;
  font-size: 34px;
  color: #1bbca3;
  float: left;
  line-height: 0;
}

.counts .count-box span {
  font-size: 42px;
  line-height: 24px;
  display: block;
  font-weight: 700;
  color: #58716d;
  margin-left: 50px;
}

.counts .count-box p {
  padding: 30px 0 0 0;
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
}

.counts .count-box a {
  font-weight: 600;
  display: block;
  margin-top: 20px;
  color: #7c9995;
  font-size: 15px;
  font-family: "Poppins", sans-serif;
  transition: ease-in-out 0.3s;
}

.counts .count-box a:hover {
  color: #a7bbb8;
}

/*--------------------------------------------------------------
# Our Services
--------------------------------------------------------------*/
.services .icon-box {
  padding: 30px;
  position: relative;
  overflow: hidden;
  background: #fff;
  box-shadow: 0 0 29px 0 rgba(68, 88, 144, 0.12);
  transition: all 0.3s ease-in-out;
  border-radius: 8px;
  z-index: 1;
}

.services .icon-box::before {
  content: '';
  position: absolute;
  /*background: #aff4e9;*/
  background: #d06a53;
  right: -60px;
  top: -40px;
  width: 100px;
  height: 100px;
  border-radius: 50px;
  transition: all 0.3s;
  z-index: -1;
}



.services .icon {
  margin: 0 auto 20px auto;
  padding-top: 10px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  /*background: #1bbca3;*/
  background: #d06a53;
  transition: all 0.3s ease-in-out;
}

.services .icon i {
  font-size: 36px;
  line-height: 1;
  color: #fff;
}

.services .title {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 18px;
}

.services .title a {
  /*color: #425451;*/
  color: #d06a53; 
}

.services .description {
  font-size: 15px;
  line-height: 28px;
  margin-bottom: 0;
}


/*-------------------------------------------------------------
# Home
--------------------------------------------------------------*/
.home {
  /*background: linear-gradient(rgba(26, 47, 238, 0.5), rgba(12, 27, 165, 0.5)), url("assets/img/slide-4.jpeg") fixed center center;*/
  /*background: linear-gradient(rgba(206, 206, 156, 0.5), rgba(232, 201, 92, 0.5)), url("assets/img/slide-4.jpeg") fixed center center;*/
  background: linear-gradient(rgba(21, 27, 26, 0.7), rgba(21, 27, 26, 0.7)), url("assets/img/slide/slide-0.jpg") fixed center center;
  background-size: cover;
  padding: 60px 0;
  min-height: 100vh;
  min-width: 100%;
}

.home h3 {
  color: #fff;
  font-size: 28px;
  font-weight: 700;
}


.home h2 {
  color: #fff;
  margin-bottom: 30px;
  font-size: 48px;
  font-weight: 700;
}

.home p {
  width: 80%;
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
  margin: 0 auto 30px auto;
  color: #fff;
}

.home .home-btn {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 8px 28px;
  border-radius: 25px;
  transition: 0.5s;
  margin-top: 10px;
  border: 2px solid #fff;
  color: #fff;
}

.home .home-btn:hover {
  background: #fff;
  border: 2px solid #fff;
  color: #d06a53;
  cursor: pointer;
}

/*--------------------------------------------------------------
# Cta
--------------------------------------------------------------*/
.cta {
  /*background: linear-gradient(rgba(26, 47, 238, 0.5), rgba(12, 27, 165, 0.5)), url("assets/img/slide-4.jpeg") fixed center center;*/
  /*background: linear-gradient(rgba(206, 206, 156, 0.5), rgba(232, 201, 92, 0.5)), url("assets/img/slide-4.jpeg") fixed center center;*/
  background: linear-gradient(rgba(232, 201, 92, 0.6), rgba(208, 106, 83, 0.6)), url("assets/img/slide-5.png") fixed center center;
  background-size: cover;
  padding: 60px 0;
}

.cta h3 {
  color: #fff;
  font-size: 28px;
  font-weight: 700;
}

.cta p {
  color: #fff;
}


.cta .cta-btn {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 8px 28px;
  border-radius: 25px;
  transition: 0.5s;
  margin-top: 10px;
  border: 2px solid #fff;
  color: #fff;
}

.cta .cta-btn:hover {
  background: #fff;
  border: 2px solid #fff;
  color: #d06a53;
  cursor: pointer;
}

/*--------------------------------------------------------------
# More Services
--------------------------------------------------------------*/
.more-services img {
  border-radius: 0;
}

.more-services .card {
  border: 0;
  text-align: ceneter;
}

.more-services .card-body {
  -moz-text-align-last: center;
  text-align-last: center;
}

.more-services .card-title a {
  font-weight: 600;
  font-size: 18px;
  color: #334240;
  transition: ease-in-out 0.3s;
}

.more-services .card-title a:hover {
  color: #1bbca3;
}

.more-services .btn {
  border-radius: 50px;
  padding: 4px 30px 6px 30px;
  border: 2px solid #1bbca3;
}

.more-services .btn:hover {
  color: #fff;
  background: #1bbca3;
}

/*--------------------------------------------------------------
# Info Box
--------------------------------------------------------------*/
.info-box .content {
  padding: 60px 100px 0 100px;
}

.info-box .content h3 {
  font-weight: 400;
  font-size: 34px;
}

.info-box .content h4 {
  font-size: 20px;
  font-weight: 700;
  margin-top: 5px;
}

.info-box .content p {
  font-size: 15px;
  color: #6b8a86;
}

.info-box .img {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  min-height: 400px;
}

.info-box .accordion-list {
  padding: 0 100px 60px 100px;
}

.info-box .accordion-list ul {
  padding: 0;
  list-style: none;
}

.info-box .accordion-list li + li {
  margin-top: 15px;
}

.info-box .accordion-list li {
  padding: 20px;
  border: 2px solid #f2f5f4;
  border-radius: 5px;
}

.info-box .accordion-list a {
  display: block;
  position: relative;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  padding-right: 30px;
  outline: none;
  transition: ease-in-out 0.3s;
  cursor: pointer;
  color: #d06a53;
}

.info-box .accordion-list a:hover {
  color: #e8c95c;
}

.info-box .accordion-list span {
  color: #d06a53;
  font-weight: 600;
  font-size: 18px;
  padding-right: 10px;
}

.info-box .accordion-list i {
  font-size: 24px;
  position: absolute;
  right: 0;
  top: 0;
}

.info-box .accordion-list p {
  margin-bottom: 0;
  padding: 10px 0 0 0;
}

.info-box .accordion-list .icon-show {
  display: none;
}

.info-box .accordion-list a.collapsed {
  color: #343a40;
}

.info-box .accordion-list a.collapsed:hover {
  color: #e8c95c;
}

.info-box .accordion-list a.collapsed .icon-show {
  display: inline-block;
}

.info-box .accordion-list a.collapsed .icon-close {
  display: none;
}

@media (max-width: 1024px) {
  .info-box .content, .info-box .accordion-list {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 992px) {
  /*.info-box {
     img {
      padding-top: 30px;
    } 
  }*/
  .info-box .content {
    padding-top: 30px;
  }
  .info-box .accordion-list {
    padding-bottom: 30px;
  }
}

/*--------------------------------------------------------------
# Our Portfolio
--------------------------------------------------------------*/
.portfolio .portfolio-item {
  margin-bottom: 30px;
}

.portfolio #portfolio-flters {
  padding: 0;
  margin: 0 auto 35px auto;
  list-style: none;
  text-align: center;
  background: #fff;
  border-radius: 50px;
  padding: 2px 15px;
}

.portfolio #portfolio-flters li {
  cursor: pointer;
  display: inline-block;
  padding: 10px 15px 8px 15px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
  color: #334240;
  margin-bottom: 5px;
  transition: all 0.3s ease-in-out;
}

.portfolio #portfolio-flters li:hover, .portfolio #portfolio-flters li.filter-active {
  color: #1bbca3;
}

.portfolio #portfolio-flters li:last-child {
  margin-right: 0;
}

.portfolio .portfolio-wrap {
  transition: 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.portfolio .portfolio-wrap::before {
  content: "";
  background: rgba(88, 113, 109, 0.6);
  position: absolute;
  left: 30px;
  right: 30px;
  top: 30px;
  bottom: 30px;
  transition: all ease-in-out 0.3s;
  z-index: 2;
  opacity: 0;
}

.portfolio .portfolio-wrap .portfolio-info {
  opacity: 0;
  position: absolute;
  top: 10%;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 3;
  transition: all ease-in-out 0.3s;
}

.portfolio .portfolio-wrap .portfolio-info h4 {
  font-size: 20px;
  color: #fff;
  font-weight: 600;
}

.portfolio .portfolio-wrap .portfolio-info p {
  color: #ffffff;
  font-size: 14px;
  text-transform: uppercase;
}

.portfolio .portfolio-wrap .portfolio-links {
  opacity: 0;
  left: 0;
  right: 0;
  bottom: 10%;
  text-align: center;
  z-index: 3;
  position: absolute;
  transition: all ease-in-out 0.3s;
}

.portfolio .portfolio-wrap .portfolio-links a {
  color: #fff;
  margin: 0 2px;
  font-size: 28px;
  display: inline-block;
  transition: 0.3s;
}

.portfolio .portfolio-wrap .portfolio-links a:hover {
  color: #56e7d0;
}

.portfolio .portfolio-wrap:hover::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
}

.portfolio .portfolio-wrap:hover .portfolio-info {
  opacity: 1;
  top: calc(50% - 48px);
}

.portfolio .portfolio-wrap:hover .portfolio-links {
  opacity: 1;
  bottom: calc(50% - 50px);
}

/*--------------------------------------------------------------
# Portfolio Details
--------------------------------------------------------------*/
.portfolio-details {
  padding-top: 40px;
}

.portfolio-details .portfolio-details-slider img {
  width: 100%;
}

.portfolio-details .portfolio-details-slider .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1;
  border: 1px solid #1bbca3;
}

.portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #1bbca3;
}

.portfolio-details .portfolio-info {
  padding: 30px;
  box-shadow: 0px 0 30px rgba(88, 113, 109, 0.08);
}

.portfolio-details .portfolio-info h3 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
}

.portfolio-details .portfolio-info ul {
  list-style: none;
  padding: 0;
  font-size: 15px;
}

.portfolio-details .portfolio-info ul li + li {
  margin-top: 10px;
}

.portfolio-details .portfolio-description {
  padding-top: 30px;
}

.portfolio-details .portfolio-description h2 {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 20px;
}

.portfolio-details .portfolio-description p {
  padding: 0;
}

/*--------------------------------------------------------------
# Our Team
--------------------------------------------------------------*/
.team {
  background: #fff;
  padding: 60px 0;
}

.team .member {
  text-align: center;
  margin-bottom: 20px;
  /*background: #343a40;*/
  position: relative;
  overflow: hidden;
}

.team .member .member-info {
  opacity: 0;
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  transition: 0.2s;
}

.team .member .member-info-content {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 10px;
  transition: bottom 0.4s;
}

.team .member .member-info-content h4 {
  font-weight: 700;
  margin-bottom: 2px;
  font-size: 18px;
  color: #fff;
}

.team .member .member-info-content span {
  font-style: italic;
  display: block;
  font-size: 13px;
  color: #fff;
}

.team .member .social {
  position: absolute;
  left: 0;
  bottom: -38px;
  right: 0;
  height: 48px;
  transition: bottom ease-in-out 0.4s;
  text-align: center;
}

.team .member .social a {
  transition: color 0.3s;
  color: #fff;
  margin: 0 10px;
  display: inline-block;
}

.team .member .social a:hover {
  color: #4154f1;
}

.team .member .social i {
  font-size: 18px;
  margin: 0 2px;
}

.team .member:hover .member-info {
  /*background: linear-gradient(0deg, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0.8) 20%, rgba(0, 212, 255, 0) 100%);*/
  opacity: 1;
  transition: 0.4s;
}

.team .member:hover .member-info-content {
  bottom: 60px;
  transition: bottom 0.4s;
}

.team .member:hover .social {
  bottom: 0;
  transition: bottom ease-in-out 0.4s;
}

/*--------------------------------------------------------------
# Contact Us
--------------------------------------------------------------*/
.contact .infos {
  position: relative;
  z-index: 2;
}

.contact .info {
  padding: 60px;
  background: #fff;
  color: #334240;
  text-align: center;
  /*box-shadow: -5px -5px 40px 0 rgba(0, 0, 0, 0.1);*/
}

.contact .info i {
  font-size: 48px;
  color: #d06a53;
  margin-bottom: 15px;
}

.contact .info h4 {
  padding: 0;
  margin: 0 0 10px 0;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
}

.contact .info p {
  font-size: 15px;
}

.contact .info-bg {
  background: white;
}

.contact .contact-form-wrap {
  /*box-shadow: 10px -5px 40px 0 rgba(0, 0, 0, 0.1);*/
  background: #fff;
  position: relative;
  z-index: 1;
  padding: 30px;
}

.contact .php-email-form {
  width: 100%;
}

.contact .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .error-message br + br {
  margin-top: 25px;
}

.contact .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}

.contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}

.contact .php-email-form label {
  font-family: "Poppins", sans-serif;
  margin-bottom: 5px;
  color: #5f7c78;
}

.contact .php-email-form input, .contact .php-email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
}

.contact .php-email-form input::focus, .contact .php-email-form textarea::focus {
  background-color: #1bbca3;
}

.contact .php-email-form input {
  padding: 10px 15px;
}

.contact .php-email-form textarea {
  padding: 12px 15px;
}

.contact .php-email-form button[type="submit"]{
  background: #fff;
  border: 2px solid #d06a53;
  padding: 10px 24px;
  color: #d06a53;
  transition: 0.4s;
}

.contact .php-email-form button[type="submit"]:hover:enabled{
  background: #d06a53;
  color: #fff;
}

.contact .php-email-form button[type="submit"]:disabled{
  border: 2px solid #F5A694;
  color: #F5A694;
}

@media (max-width: 1024px) {
  .contact .info {
    padding: 30px;
  }
}

@media (max-width: 768px) {
  .contact .php-email-form {
    padding: 15px 0 0 0;
  }
}

@-webkit-keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

